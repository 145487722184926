import request from '@/utils/request'


// 查询用户会员套餐订单列表
export function listComboOrder(query) {
    return request({
        url: '/vip/vip-combo-order/list',
        method: 'get',
        params: query
    })
}

// 查询用户会员套餐订单分页
export function pageComboOrder(query) {
    return request({
        url: '/vip/vip-combo-order/page',
        method: 'get',
        params: query
    })
}

// 查询用户会员套餐订单详细
export function getComboOrder(data) {
    return request({
        url: '/vip/vip-combo-order/detail',
        method: 'get',
        params: data
    })
}

// 新增用户会员套餐订单
export function addComboOrder(data) {
    return request({
        url: '/vip/vip-combo-order/add',
        method: 'post',
        data: data
    })
}

// 修改用户会员套餐订单
export function updateComboOrder(data) {
    return request({
        url: '/vip/vip-combo-order/edit',
        method: 'post',
        data: data
    })
}

// 删除用户会员套餐订单
export function delComboOrder(data) {
    return request({
        url: '/vip/vip-combo-order/delete',
        method: 'post',
        data: data
    })
}
